@import '~antd/dist/antd.css';


body {
  margin: 0;
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rtl {
  direction: rtl;
}

html {
  background-color: #fff;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  text-align: center;
}

.header {
  padding-top: 34px;
}

.header .anticon {
  font-size: 46px;
  margin-right: 12px;
}

.title {
  font-size:calc(25px + 1.5vw);
  padding-bottom: 12px;
}

.footer {
  margin-bottom: 32px
}

.footer .anticon {
  margin: 0 12px;
  font-size: 17px;
}

.header > .sub-title {
  font-size: 22px;
}

.sub-title {
  font-size: 16px;
}

.phone-number a{
  text-decoration: none;
  color: gray;
}

.phone-number a:hover {
  text-decoration: none;
  color: darkgray;
}



body, #root, .page-wrapper {
  height: 100%;
  background-color: #fcfcfc;
}

.Demo__map-maker-icon {
  color: #FD6C6C;
}

.Demo__github-icon {
  font-size: 24px;
}

.Demo__github-link {
  color: #262626;
  font-size: 20px;
}

.Demo__github-link:hover,
.Demo__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.Demo__suggestion-icon {
  margin-right: 8px;
}


#autocomplete-input, #autocomplete-input:focus {
  width: 500px;
  max-width: 90%;
  padding: 16px;
  font-size: 16px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  direction: rtl;
  border-radius: 2px;
  outline: none;
  margin: 40px 16px 0 auto;
}

.search-bar .ant-input-suffix{
  padding-top: 16px;
}


.clear-button {
  position: relative;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
  left: 32px;
  z-index: 3;
}

.pac-container {
  font-family: Roboto, serif;
}

.ant-card-body {
  padding: 12px 24px;
}