.App {
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.changelog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.ant-card-head-title {
    text-align: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.gallery {
    display: flex;
    margin: 32px auto;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    max-width: 80%;
    align-content: center;
}

.delivery-content {
    margin-top: 12px;
    font-weight: 400;
    text-align: right;
}

.available-container {
    display: flex;
    justify-content: space-between;
}

.available-label {
    white-space: nowrap;
}

.available-date {
    font-weight: 600;
    text-align: left;
    display: inline-block;
}

.available-date-container {
    text-align: left;
}

.logos {
    display: flex;
    width: 50vw;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
}

.logo-img {
    height: 60px;
    margin: 22px 32px;
}

.ribbon {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon span {
    font-size: 11px;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #94cc0d;
    /*background: #9BC90D 0%,  100%);*/
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
}

.ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #79A70A;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
}

.footer-text {
    font-size: 13px;
}

.fast-search-label {
    margin-left: 12px;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;

}

.fast-search-container {
    margin-top: 12px;
}

.switch-address {
    margin-top: 12px;
    text-decoration: underline;
    cursor: pointer;
    font-size: 18px;
}

.switch-address > .anticon {
    margin-right: 5px;
}

.about-us-link {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px 24px;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.about-us-link > .anticon {
  margin-right: 5px;
}

.next-delivery-text {
    margin-top: 6px;
    font-style: italic;
}